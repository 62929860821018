import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Dialog, Select, IconButton, Paper, Alert } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, Debug, MenuTop, Menu, ValidateEmail, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faCar, faCheck, faMinus, faMinusCircle, faMinusSquare, faPersonWalking, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import GoogleMapReact from 'google-map-react';
import ReCAPTCHA from 'react-google-recaptcha'


export const Contact = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);

    const [items, setItems] = useState([]);

    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState('');
    const [note, setNote] = useState('');
    const [error, setError] = useState('');
    const [showSended, setShowSended] = useState(false);
    const [company, setCompany] = useState([]);
    const [stock, setStock] = useState([]);

    const [recaptcha, setRecaptcha] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const lat = 48.382249;
    const lng = 18.395264;
    const mapLink = "http://www.google.com/maps/place/" + lat + "," + lng

    const logo = require('./react/app/logo_flower_small.png');
    const logo_full = require('./react/app/logo.png');
    const pin = require('./react/app/pin.png');
    const img = require('./react/app/img_2.jpg');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        db_contact();

        GoHome();

        return () => {
        };

    }, []);

    const db_contact = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'contact', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                var tmp = json.contact;
                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setItems(tmp);
                setStock(json.web_stock);
                setCompany(json.web);
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const db_send = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'message_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    mobil: mobil,
                    email: email,
                    note: note,
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                setShowSended(true);
                setName('');
                setMobil('');
                setEmail('');
                setNote('');

            } else {
                setError(lang.db_error);
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const GotoBack = () => {
        navigate(-1);
    }

    const Send = () => {
        var error = false;
        setError('');
        setNameErr('');
        setEmailErr('');

        if (name.trim() == '') {
            error = lang.required;
            setNameErr(lang.required);
        }
        if (email.trim() == '') {
            error = lang.required;
            setEmailErr(lang.required);
        } else {
            if (ValidateEmail(email) == false) {
                error = lang.email_error;
                setEmailErr(lang.email_error);
            }
        }
        if (note.trim() == '') {
            error = lang.message_text_error;
        }

        if (error != '') {
            setError(error);
        } else {
            if (recaptcha != '') {
                // VSETKO OK
                db_send();
            }
        }
    }

    const AnyReactComponent = ({ text }) => (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
            <img src={pin} height={40} />
            <p style={{ ...styles.TextSmall, marginTop: 12, fontWeight: 'bold', color: global.theme_dark_red }}>Polplast</p>
        </div>
    );

    const onChangeCaptcha = (value) => {
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_white }}>

            <div style={{ height: 400, width: isLarge ? '100%' : 300 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g' }}
                    defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                    defaultZoom={17}
                >
                    <AnyReactComponent
                        lat={lat}
                        lng={lng}
                    />
                </GoogleMapReact>

                {isLoading == true ?
                    <Loading></Loading>
                    : null}
            </div>

            <div style={{ ...styles.BlockMaxLarge, marginTop: 50 }} >
                <p style={{ ...styles.TextLabel }}>{lang.contact_data}</p>
            </div>

            {/* COMPANY */}
            <div style={{ ...styles.BlockMaxLarge, marginTop: 50 }} >
                <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_gray }}>
                    <div style={{ ...styles.Block, width: '50%', padding: 20 }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <img src={logo_full} style={{ width: isSmall ? 200 : 300, height: 'auto' }}></img>
                            {/* CENTRALA */}
                            <p style={{ ...styles.TextLarge, color: global.theme_black, fontWeight: 'bold', marginTop: 20 }}>{lang.contact_central}:</p>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginTop: 8 }}>{company.company}</p>                            
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginTop: 3 }}>{company.street} {company.street_number}</p>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginTop: 3 }}>{company.psc} {company.town}</p>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginTop: 3 }}>{company.state}</p>                            
                            <div style={{ ...styles.BlockRow, marginTop: 15 }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'lighter' }}>{lang.ico}:</p>
                                <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginLeft: 10 }}>{company.ico}</p>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 3 }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'lighter' }}>{lang.dic}:</p>
                                <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginLeft: 10 }}>{company.dic}</p>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 3 }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'lighter' }}>{lang.ic_dph}:</p>
                                <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginLeft: 10 }}>{company.ic_dph}</p>
                            </div>

                            {/* SKLAD */}
                            <p style={{ ...styles.TextLarge, color: global.theme_black, fontWeight: 'bold', marginTop: 20 }}>{lang.contact_stock}:</p>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginTop: 8 }}>{stock.street} {stock.street_number}</p>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginTop: 3 }}>{stock.psc} {stock.town}</p>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'normal', marginTop: 3 }}>{stock.state}</p>

                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '50%' }}>
                        <img src={img} style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
            </div>

            <div style={{ ...styles.BlockMaxLarge, marginTop:0 }} >
                    <div style={{ ...isSmall ? styles.BlockSmall : styles.BlockMaxLarge, justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 50, marginBottom: 50 }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faCar} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0, textAlign: 'left' }}>Ako nás nájdete autom?</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left' }}>Do navigácie si zadajte Ul. 1. mája 4 v Zlatých Moravciach.</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left'}}>GPS Vás nasmeruje do centra mesta a pod mestským úradom zabočíte hneď na naše parkovisko!</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left', marginTop:10 }}>Súradnice GPS (LAT: {lat}, LNG: {lng})</p>
                                <a style={{marginTop:20 }} href={mapLink} target="_blank">Google maps</a>
                            </div>
                        </div>
                        {/*
                        <div style={{ ...styles.BlockRow, marginTop: 40 }}>
                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faPersonWalking} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0, textAlign: 'left' }}>Ako k nám prísť pešo?</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left' }}>Z námestia A. Hlinku 9. Vchod nájdete priamo z námestia pod Slovenskou sporiteľňou.</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left' }}>Vyznačené dvere s reklamou sú na konci dvora s butikmi a kaviarňami.</p>
                            </div>
                        </div>
                        */}
                    </div>
                </div>


            <div style={{ ...styles.Block, marginTop: 40, backgroundColor: global.theme_gray }} >
                <div style={{ ...styles.BlockRow, flexWrap: 'wrap', maxWidth: 1000, marginTop: 40, marginBottom: 40 }} >

                    {/* KONTAKTY */}
                    {
                        items.map(item => {
                            return (
                                <Paper key={item.id} elevation={0} style={{ ...styles.BlockRow, backgroundColor: global.theme_white, paddingTop: 10, paddingBottom: 10, borderTopLeftRadius: 50, width: 460, height: 200, paddingRight: 10, margin: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: 100 }}>
                                        {item.image != null ?
                                            <img src={global.images + 'contact/' + item.image} style={{ width: 100, height: 100 }}></img>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '100%', marginLeft: 20 }}>
                                        <div style={{ ...styles.BlockLeft, borderBottom: '1px solid ' + global.theme_gray }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: 'bold', color: global.theme_black, marginBottom: 10, textAlign: 'left' }}>{item.label}</p>
                                        </div>
                                        {item.name != null ?
                                            <p style={{ ...styles.TextNormal, fontWeight: 'normal', color: global.theme_black, marginTop: 10 }}>{item.name}</p>
                                            : null}
                                        {item.contact_label_1 != null ?
                                            <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'lighter', color: global.theme_dark_gray }}>{item.contact_label_1}</p>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'normal', color: global.theme_black, marginLeft: 5 }}>{item.contact_1}</p>
                                            </div>
                                            : null}
                                        {item.contact_label_2 != null ?
                                            <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'lighter', color: global.theme_dark_gray }}>{item.contact_label_2}</p>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'normal', color: global.theme_black, marginLeft: 5 }}>{item.contact_2}</p>
                                            </div>
                                            : null}
                                        {item.contact_label_3 != null ?
                                            <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'lighter', color: global.theme_dark_gray }}>{item.contact_label_3}</p>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'normal', color: global.theme_black, marginLeft: 5 }}>{item.contact_3}</p>
                                            </div>
                                            : null}
                                        {item.contact_label_4 != null ?
                                            <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'lighter', color: global.theme_dark_gray }}>{item.contact_label_4}</p>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'normal', color: global.theme_black, marginLeft: 5 }}>{item.contact_4}</p>
                                            </div>
                                            : null}
                                        {item.contact_label_5 != null ?
                                            <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'lighter', color: global.theme_dark_gray }}>{item.contact_label_5}</p>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'normal', color: global.theme_black, marginLeft: 5 }}>{item.contact_5}</p>
                                            </div>
                                            : null}
                                        {item.language != null ?
                                            < div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'lighter', color: global.theme_dark_gray }}>{lang.language}:</p>
                                                <p style={{ ...styles.TextNormal, fontWeight: 'normal', color: global.theme_black, marginLeft: 5 }}>{item.language}</p>
                                            </div>
                                            : null}
                                    </div>
                                </Paper>
                            )
                        })}

                </div>
            </div>
            <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray }}>

                <Paper elevation={3} style={{ ...styles.Block, maxWidth: 500, marginTop: 40, marginBottom: 40 }}>
                    <div style={{ ...styles.Block, marginTop: 30 }}>
                        <div style={{ ...styles.Block, marginBottom: 20 }}>
                            <img src={logo} style={{ width: 70, height: 70 }}></img>
                        </div>
                        <p style={{ ...styles.TextLarge }}>{lang.message_question}</p>
                    </div>
                    {/* KONTAKTNE UDAJE */}
                    <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 40 }}>
                        <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 5 }}>{lang.contact_data}</p>

                        {/* MENO */}
                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                            <TextField size='small' error={nameErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.name_surname} variant="outlined" value={name} onInput={e => setName(e.target.value)} inputProps={{ maxLength: 128 }} />
                        </div>

                        {/* MOBIL */}
                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                            <TextField size='small' error={mobilErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.mobil} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} inputProps={{ maxLength: 64 }} />
                        </div>

                        {/* EMAIL */}
                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                            <TextField size='small' error={emailErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.email} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} inputProps={{ maxLength: 128 }} />
                        </div>

                    </div>

                    {/* POZNAMKA */}
                    <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 40 }}>
                        <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 5 }}>{lang.message_text}</p>

                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                            <TextField size='small' multiline={true} rows={4} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} inputProps={{ maxLength: 255 }} />
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '95%', marginTop: 20 }}>
                        <ReCAPTCHA
                            sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                            onChange={onChangeCaptcha}
                        />
                    </div>

                    <div style={{ ...styles.Block, width: '95%', height: 40, marginTop: 20, marginBottom: 20 }}>
                        {error != '' ?
                            <Alert severity="error">{error}</Alert>
                            : null}
                    </div>

                    {/* ODOSLAT */}
                    <div style={{ ...styles.Block, width: '95%', marginTop: 10, marginBottom: 20 }}>
                        <Button onClick={() => { Send() }} variant="contained" style={{ ...styles.ButtonGreen }}>{lang.send}</Button>
                    </div>

                </Paper>

            </div>
            {
        showSended == true ?
        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => setShowSended(false)}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: 350, height: 200 }}>
                {/* BODY */}
                <div style={{ ...styles.Block, width: '100%', height: '100%', backgroundColor: global.theme_white }}>
                    <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                        <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                    </div>
                    <p style={{ ...styles.TextSmall, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.message_send_od}</p>

                </div>

                {/* BUTTON */}
                <div style={{ ...styles.Block, flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <Button onClick={() => setShowSended(false)} variant="contained" style={{ ...styles.ButtonDark, width: 200 }}>{lang.close}</Button>
                    </div>
                </div>
            </div>
        </Dialog>
        : null
    }

    {
        isLoading == true ?
            <Loading></Loading>
            : null
    }

        </div >
    )
};
